@import 'components/kit-core/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* TOOLTIPS */

.tooltip {
  .tooltip-inner {
    background: $black;
    padding: rem(4) rem(15);
    color: $white;
    font-size: rem(14);
  }
}
