// Import mixins
@import 'components/kit-core/mixins.scss';

.ant-select {
  &-open {
    .ant-select-selection {
      border-color: $primary;
    }
  }

  &-selection {
    &:hover {
      border-color: $primary;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-select {
    &-multiple {
      .ant-select-selection-item {
        color: $dark-gray-1;
        background: $primary;

        &-remove {
          color: $dark-gray-1;
        }
      }
    }

    &-item-option-active:not(.ant-select-item-option-disabled),
    &-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $dark-gray-1;
      background: $primary;
    }

    // antd v3 styles
    &-selection {
      &--multiple {
        .ant-select-selection__choice {
          color: $dark-gray-1;
          background: $primary;

          &-remove {
            color: $dark-gray-3;
          }
        }
      }
    }

    &-dropdown-menu-item {
      &-selected {
        color: $dark-gray-1;
        background: $primary;
      }
    }

    &-tree-dropdown {
      .ant-select-dropdown-search {
        .ant-select-search__field {
          background: $dark-gray-5;
        }
      }
    }
  }
}
