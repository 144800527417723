// Import mixins
@import 'components/kit-core/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-empty {
    svg * {
      fill: $gray-5;
      stroke: $gray-6;
    }
  }
}
