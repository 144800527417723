// Import mixins
@import 'components/kit-core/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-breadcrumb {
    &-link,
    &-link a {
      color: $dark-gray-1;
    }
    &-separator {
      color: $dark-gray-3;
    }
  }
}
